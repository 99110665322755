<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import axios from "axios";
import appConfig from "../../../../src/app.config";

export default {
  locales: {
    pt: {
      "My Documents": "Meus Documentos",
      "The document is being evaluated": "O documento esta sendo aváliado.",
      "The document has been send": "Documento enviado com sucesso!",
      "The document is being evaluated.": "O documento está sendo validado.",
      "Choose the file": "Escolha o arquivo",
      Send: "Enviar",
      Disapproved: "Reprovado",
      "On approval": "Em análise",
      Okay: "Aprovado",
      Resend: "Reenviar",
    },
    es: {
      "My Documents": "Mis documentos",
      "The document is being evaluated": "El documento esta siendo evaluado.",
      "The document has been send": "El documento ha sido enviado.",
      "The document is being evaluated.": "El documento está siendo evaluado.",
      "Choose the file": "Elija el archivo",
      Send: "Enviar",
      Disapproved: "Desaprobado",
      "On approval": "En aprovacion",
      Okay: "Okey",
      Resend: "Reenviar",
    },
  },
  components: { Layout },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      documents: {
        modal: false,

        loading: false,
        errored: false,
        status: false,
        list: null,
      },

      choosed: null,
      file: null,

      modal: {
        loading: false,
        status: false,
        id: null,
        title: null,
        file: null,
      },
    };
  },
  methods: {
    getDocuments: function () {
      this.documents.loading = true;

      api
        .get("user/documents")
        .then((response) => {
          if (response.data.status == "success") {
            this.documents.list = response.data.documents;
          } else {
            this.documents.list = null;
          }
        })
        .catch((error) => {
          this.documents.errored = error;
          this.documents.loading = false;
        })
        .finally(() => {
          this.documents.loading = false;
        });
    },
    setDocument() {
      this.modal.loading = true;

      let formData = new FormData();

      formData.append("file", this.file);

      var config = {
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(
          appConfig.apiUrl + "user/documents/" + this.modal.id,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.$toast.success(this.t(response.data.message));
            this.getDocuments();
            this.modal.status = false;
          } else {
            this.$toast.error(this.t(response.data.message));
            this.getDocuments();
            this.modal.status = false;
          }
          this.modal.loading = false;
        });
    },
    onFileSelected() {
      this.file = this.$refs.file.files[0];
      this.choosed = this.file.name;
    },

    addDocument: function (document) {
      if (
        document.file.status == "repproved" ||
        document.file.status == "waiting" ||
        document.file.status == null
      ) {
        this.modal.title = document.types.description;
        this.modal.id = document.types.id;
        this.choosed = null;
        this.modal.status = true;
      }
    },
  },
  mounted() {
    this.getDocuments();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("My Documents") }}</h4>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-for="(document, index) in documents.list" :key="index">
      <div class="col-md-12 col-lg-7 col-xl-6" @click="addDocument(document)">
        <div class="card mb-1">
          <div class="card-body p-2 px-4">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="text-muted font-weight-medium m-0">
                {{ document.types.title }}
              </h5>
              <div class="flex-fill text-right">
                <div class="badge badge-danger p-2 m-2 font-size-12 text-uppercase font-weight-bold" v-if="document.file.status == 'repproved'">
                  {{ t("Resend") }}
                </div>
                <div class="badge badge-warning p-2 m-2 font-size-12 text-uppercase font-weight-bold" v-else-if="document.file.status == 'pending'">
                  {{ t("On approval") }}
                </div>
                <div class="badge badge-success p-2 m-2 font-size-12 text-uppercase font-weight-bold" v-else-if="document.file.status == 'approved'">
                  Aprovado
                </div>
                <button class="btn btn-default btn-sm m-2 text-uppercase" v-else @click="addDocument(document)">
                  {{ t("Send") }}
                </button>
              </div>
              <a v-if="document.file.url" class="align-items-center pt-1" target="_blank" :href="document.file.url">
                <i class="bx bx-file h3 m-0 p-0"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modal.status" :title="t('Send') + ' ' + modal.title" centered hide-footer>
      <div class="col-12">
        <b-form @submit.prevent="setDocument">
          <div class="d-flex align-items-center justify-content-between pb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="file">Upload</span>
              </div>
              <div class="custom-file">
                <input type="file" id="file" ref="file" class="custom-file-input" @change="onFileSelected" aria-describedby="inputGroupFileAddon01" />
                <label class="custom-file-label" for="file" v-if="choosed == null">{{ t("Choose the file") }}</label>
                <label class="custom-file-label" for="file" v-else>{{
                  choosed
                  }}</label>
              </div>
            </div>
          </div>
          <b-button class="btn-block text-uppercase" type="submit" variant="default" :disabled="modal.loading">
            {{ t("Send") }}
            <b-spinner v-if="modal.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </b-button>
        </b-form>
      </div>
    </b-modal>
  </Layout>
</template>
